import React from 'react';
import { silentAuth } from './src/utils/auth';

// Bootstrap styling and JS imports.
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';

class SessionCheck extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
    };
  }

  // Once a page has loaded, silently authenticate the user.
  async componentDidMount() {
    await silentAuth();
    this.setState({ loading: false });
  }

  // Only render something when loading is finished.
  render() {
    return (
      this.state.loading === false && (
        <React.Fragment>{this.props.children}</React.Fragment>
      )
    );
  }
}

// Root component is only mounted once. Wrap the root with a sessionCheck component
// to silently authenticate the user on page load.
export const wrapRootElement = ({ element }) => {
  return <SessionCheck>{element}</SessionCheck>;
};
