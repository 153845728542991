import createAuth0Client from '@auth0/auth0-spa-js';
import { navigate } from 'gatsby';

// The landing page users are sent to after a successful authentication.
const SUCCESSFUL_AUTH_LANDING_PAGE = '/app';
// The landing page users are sent to after a failed authentication.
const FAILED_AUTH_LANDING_PAGE = '/';

// Check if in a browser.
const inBrowser = typeof window !== 'undefined';

// URL to go to for Auth0 callbacks.
let CALLBACK_URL = '';
switch (process.env.GATSBY_ENV) {
  case 'production':
    CALLBACK_URL = process.env.GATSBY_AUTH0_PROD_CALLBACK;
    break;
  case 'staging':
    CALLBACK_URL = process.env.GATSBY_AUTH0_STAGING_CALLBACK;
    break;
  default:
    CALLBACK_URL = process.env.GATSBY_AUTH0_DEV_CALLBACK;
    break;
}

// URL to go to after logging out.
let LOGOUT_URL = '';
switch (process.env.GATSBY_ENV) {
  case 'production':
    LOGOUT_URL = process.env.GATSBY_AUTH0_PROD_LOGOUT_URL;
    break;
  case 'staging':
    LOGOUT_URL = process.env.GATSBY_AUTH0_STAGING_LOGOUT_URL;
    break;
  default:
    LOGOUT_URL = process.env.GATSBY_AUTH0_DEV_LOGOUT_URL;
    break;
}

// If in browser, create or return singleton auth0 client.
let auth0Client = null;
const getAuth0Client = async () => {
  if (!auth0Client) {
    auth0Client = inBrowser
      ? await createAuth0Client({
          domain: process.env.GATSBY_AUTH0_DOMAIN,
          client_id: process.env.GATSBY_AUTH0_CLIENTID,
          redirect_uri: CALLBACK_URL,
          scope: 'openid profile email',
        })
      : {};
  }
  return auth0Client;
};

// If in browser, send user to callback page to log in.
export const login = () => {
  if (!inBrowser) {
    return;
  }
  getAuth0Client().then(async (auth0) => {
    await auth0.loginWithRedirect();
  });
};

// If in browser, start authentication process and redirect to landing page
// based on authentication result.
export const handleAuthentication = () => {
  if (!inBrowser) {
    return;
  }
  getAuth0Client()
    .then((auth0) => {
      return auth0.handleRedirectCallback();
    })
    .then(() => {
      navigate(SUCCESSFUL_AUTH_LANDING_PAGE);
    })
    .catch(() => {
      navigate(FAILED_AUTH_LANDING_PAGE);
    });
};

// If in browser, check if user is authenticated.
export const isUserAuthenticated = async () => {
  if (!inBrowser) {
    return;
  }
  const auth0 = await getAuth0Client();
  return await auth0.isAuthenticated();
};

// Get user data.
export const getUserProfile = async () => {
  const auth0 = await getAuth0Client();
  return await auth0.getUser();
};

// If user is not authenticated, return. If user is authenticated, check their
// user session and get a new token.
export const silentAuth = async () => {
  const isAuthenticated = await isUserAuthenticated();
  if (!isAuthenticated) {
    return;
  }
  const auth0 = await getAuth0Client();
  await auth0.checkSession();
  return;
};

// Logout user and send to logout page.
export const logout = async () => {
  const auth0 = await getAuth0Client();
  auth0.logout({ returnTo: LOGOUT_URL });
};
